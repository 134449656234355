<template>
  <div class="row">
    <div class="col-md-12">
      <Card :title="$t(batch.isTypeOffers() ? 'stocks.batches.edit.title_offers' : 'stocks.batches.edit.title', {farmer: batch.getFarm().getName() || '-'})">
        <template v-slot:default>
          <div class="row mb-4">
            <dt class="col-4 col-md-3 col-xl-2 font-size-lg text-muted ">{{$t('stocks.batches.edit.id')}}</dt>
            <dd class="col-8 col-md-9 col-xl-10 mb-1 font-size-lg text-muted ">
              {{batch.getId()}}
            </dd>
            <dt class="col-4 col-md-3 col-xl-2 font-size-lg text-muted " v-if="!batch.isTypeOffers()">{{$t('stocks.batches.edit.interval')}}</dt>
            <dd class="col-8 col-md-9 col-xl-10 mb-1 font-size-lg text-muted " v-if="!batch.isTypeOffers()">
              <Date :date="batch.getStartAt()"/> - <Date  :date="batch.getEndAt()"/>
            </dd>
            <dt class="col-4 col-md-3 col-xl-2 font-size-lg text-muted">{{$t('stocks.batches.edit.status')}}</dt>
            <dd class="col-8 col-md-9 col-xl-10 mb-1 font-size-lg text-muted ">
              <Status :status="batch.getStatus()"/>
            </dd>
            <dt class="col-4 col-md-3 col-xl-2 font-size-lg text-muted" v-if="batch.isTypeOffers()">{{$t('stocks.batches.edit.customer_name')}}</dt>
            <dd class="col-8 col-md-9 col-xl-10 mb-1 font-size-lg text-muted " v-if="batch.isTypeOffers()">
              {{batch.getCustomerName()}}
            </dd>
          </div>
          <div class="mb-4" v-if="!batch.getItemCollection().isEmpty() && !batch.isTypeOffers()">
            <h5 class="font-weight-semibold">{{ $t('stocks.batches.edit.available_products') }}</h5>
            <StockBatchItemTable :is-editable="isEditable" :elements="batch.getItemCollection()" />
            <br class="clearfix"/>
          </div>
          <Loading :has-text="false" v-else-if="!batch.isTypeOffers()" />
          <div v-if="false && batch.isFinished()">
            <h5 class="font-weight-semibold">{{ $t('stocks.batches.edit.harvest_products') }}</h5>
            <StockBatchItemTable :is-editable="isEditable" :elements="batch.getHarvestItemCollection()" />
            <br class="clearfix"/>
          </div>
          <div v-if="false && batch.isFinished()">
            <h5 class="font-weight-semibold">{{ $t('stocks.batches.edit.new_products') }}</h5>
              <StockBatchItemTable :is-editable="isEditable" :elements="batch.getNewItemCollection()" />
            <br class="clearfix"/>
          </div>
        </template>
        <template v-slot:footer>
          <Button class="mb-2 mb-lg-0" v-if="canSendStockBatches && !batch.canShowStatusModal() && getSendButtonIconProps(batch)" v-bind:disabled="!batch.isProcessable()" @click.stop="sendBatch(batch)" :type="getSendButtonIconProps(batch).type" :name="getSendButtonIconProps(batch).message"></Button>
          <Button class="mb-2 mb-lg-0 w-lg-auto w-100" v-else-if="batch.canShowStatusModal()" @click.stop="$emit('show', batch)" type="eye" name="stocks.batches.buttons.show"></Button>
          <Button @click.stop="migrate" class="ml-auto w-lg-auto w-100" v-if="batch.isFinished() && !batch.isTypeOffers() && canUpdateProductsBasedOnStockBatches" type="confirm" name="stocks.batches.buttons.migrate"></Button>
        </template>
      </Card>
    </div>
  </div>
  <EnGrossProductCard v-if="batch.isLoaded() && batch.isTypeFull()" :farmer-id="batch.getFarm().getId()" />
</template>

<script>
import Card from "@/components/elements/Card";
import Button from "@/components/elements/Button";
import Status from "@/components/elements/Status";
import {LoadingModalMixin, ConfirmationModalMixin} from "@/mixins/ModalMixin";
import {AuthorizedActionsMixin, NotificationsMixin, NumbersMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import Date from "@/components/elements/Date";
import StockBatchItemTable from "@/components/stock-batch/backoffice/StockBatchItemTable";
import StockBatch from "@/entities/StockBatch";
import EmittedEvent from "@/entities/EmittedEvent";
import {ScrollMixin} from "@/mixins/ViewMixin";
import Loading from "@/components/elements/Loading";
import EnGrossProductCard from "@/components/engross/backoffice/EnGrossProductCard";

export default {
  name: "BatchEdit",
  components: {
    EnGrossProductCard,
    Loading,
    StockBatchItemTable,
    Date,
    Status, Card, Button},
  emits: ['update', 'show', 'askConfirmation'],
  mixins: [NotificationsMixin, LoadingModalMixin, ConfirmationModalMixin, ScreenSizeMixin, AuthorizedActionsMixin, NumbersMixin, ScrollMixin],
  data: function () {
    return {
      batch: new StockBatch(),
      item: null,
      selectedItem: null,
    }
  },
  mounted() {
    this.getBatch();
  },
  unmounted() {
    this.$store.commit("breadcrumb", "");
  },
  computed: {
    isEditable: function () {
      return this.batch.isEditable() && !this.canShowStatusModal;
    },
    canShowStatusModal: function () {
      return this.batch.isLoaded() && this.batch.isInProgress();
    },
    rerender: function () {
      return this.$store.state.rerender;
    },
  },
  watch: {
    rerender: {
      handler: function () {
        this.getBatch();
      }
    },
  },
  methods: {
    sendBatch(batch) {
      let type = this.extractTypeFromBatch(batch);
      if (type) {
        this.notifyForAskConfirmation(new EmittedEvent(type, batch))
      }
    },

    extractTypeFromBatch(batch) {
      let type = '';

      if (batch.isNew()) {
        type = 'stocks.batches.list.confirm.send';
      } else {
        console.log('Batch status is not recognized');
      }

      return type;
    },
    getSendButtonIconProps(batch)
    {
      switch (this.extractTypeFromBatch(batch)) {
        case "":
          return null;
        default:
          return {type: 'send', message:'stocks.batches.buttons.send'};
      }
    },
    onLoadingComplete() {
      if (this.initialise) {
        this.getBatch();
        this.$emit('update');
      }
    },
    getBatch() {
      let that = this;
      let batch = null;
      this.queueJob();
      this.axios.get(this.$store.state.config.getStockBatchUri(this.$route.params.batchId)).then(
          response => {
            let content = response.data;
            batch = new StockBatch(content.data);
            batch.setLoaded(true);
          }
      ).catch(
          error => {
            console.warn(error);
          }
      ).finally(function () {
        if (batch) {
          that.batch = batch;
          that.$store.commit('breadcrumb', that.batch && that.batch.getFarm().getName());
        }
        that.completeJob();
      });
    },
    migrate() {
      let that = this;
      this.queueJob();
      this.resetGlobalNotifications();
      this.axios.put(this.$store.state.config.getMigrateStockBatchUri(this.$route.params.batchId)).then(
          () => {
            that.addGlobalSuccessNotification('stocks.batches.edit.migrate_success');
            that.batch.fakeMigration();
          }
      ).catch(
          error => {
            console.warn(error);
            that.addGlobalWarningNotification('stocks.batches.edit.migrate_failed');
          }
      ).finally(function () {
        that.scrollToTop();
        that.completeJob();
        that.$emit("update")
      });
    },
  }
}
</script>

<style scoped>
</style>
