<template>
  <Table :pagination="pagination" @page="onPage" :hoverable="false">
    <template v-slot:header>
      <TableElementItem :centered="true" :is-header="true" v-for="header in headers" v-bind:key="header">
        <template v-slot:default>
          {{ $t(header) }}
        </template>
      </TableElementItem>
    </template>
    <template @page="onPage" v-if="elements.getSize()">
      <TableElement @click="$emit('details', element)" v-for="element in paginatedElements" v-bind:key="element.getId()">
        <TableElementItem class="d-none d-lg-table-cell" :centered="true">
          <template v-slot:default>
            {{ element.getProductUuid() }}
          </template>
        </TableElementItem>
        <TableElementItem>
          <template v-slot:default>
            {{ element.getProductName() }}
          </template>
        </TableElementItem>
        <TableElementItem v-bind:class="{'text-warning': element.wasUpdated() && element.isQtyChanged(), 'text-danger': element.wasUpdated() && element.hasNoQty()}">
          <template v-slot:default>
            <Qty element-style="m-0 p-0" :amount="element.justGetQty()" :unit="element.getUnit()" /> /<span>{{$t('stocks.batches.edit.weekly')}}</span>
          </template>
        </TableElementItem>
        <TableElementItem v-bind:class="{'text-warning': element.wasUpdated() && element.isPriceChanged(), 'text-danger': element.wasUpdated() && element.hasNoPrice()}">
          <template v-slot:default>
            <Price :amount="element.justGetPriceWithVat()" :currency="element.getCurrency()" />
          </template>
        </TableElementItem>
        <TableElementItem class="d-none d-lg-table-cell">
          <template v-slot:default>
            <Price :amount="element.getDesiredPriceWithVat()" :currency="element.getCurrency()" />
          </template>
        </TableElementItem>
      </TableElement>
    </template>
    <template v-slot:footer v-if="elements.isEmpty()" >
      <TableElement :size="headers.length" :empty-message="$t('stocks.batches.edit.empty')" />
    </template>
  </Table>
</template>

<script>
import Table from "@/components/elements/Table";
import TableElementItem from "@/components/elements/TableElementItem";
import TableElement from "@/components/elements/TableElement";
import Price from "@/components/elements/Price";
import {DatesMixin, ScreenSizeMixin} from "@/mixins/GeneralMixin";
import Qty from "@/components/elements/Qty";
import Pagination from "@/entities/Pagination";

export default {
  name: "StockBatchItemTable",
  components: {Qty, Price, TableElement, TableElementItem, Table},
  mixins: [ScreenSizeMixin, DatesMixin],
  emits: ['details'],
  props: {
    elements: Object,
    itemPageSize: {
      type: Number,
      default: 15,
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      allHeaders: [
        'stocks.batches.edit.id',
        'stocks.batches.edit.product',
        'stocks.batches.edit.real_quantity',
        'stocks.batches.edit.real_price',
        'stocks.batches.edit.desired_price',
      ],
      responsiveHeaders: [
        'stocks.batches.edit.product',
        'stocks.batches.edit.real_quantity',
        'stocks.batches.edit.real_price',
      ],
      currentPage: 1,
    }
  },
  computed: {
    headers: function () {
      if (this.isScreenLgWidth) {
        return this.allHeaders;
      }

      return this.responsiveHeaders;
    },
    paginatedElements: function () {
      return this.elements.getItems().slice(
          (this.currentPage - 1) * this.itemPageSize,
          this.currentPage * this.itemPageSize
      );
    },
    pagination: function () {
      return new Pagination(
          this.currentPage,
          this.elements.getSize(),
          this.itemPageSize,
          Math.floor(this.elements.getSize() / this.itemPageSize +
          (this.elements.getSize() % this.itemPageSize > 0 ? 1 : 0))
      );
    }
  },
  methods: {
    onPage(pageNr) {
      this.currentPage = pageNr;
    },
  }
}
</script>

<style scoped>

</style>