import Changeable from "@/entities/Changeable";

export default class StockBatchItem extends Changeable
{

    item

    constructor(item)
    {
        super();
        this.item = item;
    }

    wasUpdated()
    {
        return this.isChanged() || (null !== this.item.updated_at && typeof this.item.updated_at !== "undefined");
    }

    getProductId()
    {
        return this.item.product_id;
    }

    getProductUuid()
    {
        return this.item.product_uuid;
    }

    hasProductId()
    {
        return null !== this.item.product_id;
    }

    getProductName()
    {
        return this.item.product_name;
    }

    getInitialQty()
    {
        return this.item.initial_qty;
    }

    getQty()
    {
        return this.item.qty;
    }

    getPriceWithVat()
    {
        return this.item.price;
    }

    getInitialPriceWithVat()
    {
        return this.item.initial_price;
    }

    setPriceWithVat(price)
    {
        this.item.price = price;
        this.setChanged(true);
    }

    setDesiredPriceWithVat(price)
    {
        this.item.desired_price = price;
        this.setChanged(true);
    }

    setQty(qty)
    {
        this.item.qty = qty;
        this.setChanged(true);
    }

    getId()
    {
        return this.item.id;
    }

    getUnit()
    {
        return this.item.unit || 'kg';
    }

    getCurrency()
    {
        return this.item.currency || 'RON';
    }

    isQtyChanged()
    {
        return this.wasUpdated() && this.getInitialQty() != this.getQty();
    }

    isPriceChanged()
    {
        return this.wasUpdated() && this.getInitialPriceWithVat() != this.getPriceWithVat();
    }

    hasNoQty()
    {
        return this.wasUpdated() ? 0 == this.getQty() : 0 == this.getInitialQty();
    }

    hasNoPrice()
    {
        return this.wasUpdated()  ? 0 == this.getPriceWithVat() : 0 == this.getInitialPriceWithVat();
    }

    isHarvest()
    {
        return this.item.is_harvest;
    }

    justGetPriceWithVat()
    {
        return this.wasUpdated() ? this.getPriceWithVat() : this.getInitialPriceWithVat();
    }

    justGetDesiredPriceWithVat()
    {
        return this.wasUpdated() ? this.getDesiredPriceWithVat() : this.getInitialPriceWithVat();
    }

    justGetQty()
    {
        return this.wasUpdated() ? this.getQty() : this.getInitialQty();
    }

    getDesiredPriceWithVat()
    {
        return this.item.desired_price;
    }

    getMarketMinPriceWithVat()
    {
        return this.item.market_min_price;
    }
}
