import Farmer from "@/entities/Farmer";
import Collection from "@/entities/Collection";
import StockBatchItem from "@/entities/StockBatchItem";
import Loadable from "@/entities/Loadable";

export default class StockBatch extends Loadable
{
    static editableStatuses = ['new', 'in_progress'];
    static qtyUpdateStatuses = ['in_progress',];
    static deletableStatuses = [];
    static finalStatuses = ['done', 'migrated'];

    batch;
    farm;
    items;

    constructor(batch = {id: '', status: ''})
    {
        super();
        this.batch = batch;
        this.farm = batch ? new Farmer(batch.farmer) : new Farmer();
        this.items = batch && batch.items ? new Collection(batch.items.map(item => new StockBatchItem(item))) : new Collection();
    }

    getFarm()
    {
        return this.farm;
    }

    hasEnGrossOffers()
    {
        return !!this.batch.has_en_gross;
    }

    getStatus()
    {
        return this.batch.status;
    }

    getItemCollection()
    {
        return this.items;
    }

    isReadyToBeDone()
    {
        return this.items.getItems().filter(item => !item.wasUpdated()).length === 0;
    }

    getAvailableItemCollection()
    {
        return new Collection(this.items.getItems().filter(item => !item.hasNoQty()).sort((a,b) => (a.getProductName() > b.getProductName()) ? 1 : ((b.getProductName() > a.getProductName()) ? -1 : 0)));
    }

    getNotAvailableItemCollection()
    {
        return new Collection(this.items.getItems().filter(item => item.hasNoQty()).sort((a,b) => (a.getProductName() > b.getProductName()) ? 1 : ((b.getProductName() > a.getProductName()) ? -1 : 0)));
    }

    getNewItemCollection()
    {
        return new Collection(this.items.getItems().filter(item => !item.hasProductId()))
    }

    getHarvestItemCollection()
    {
        return new Collection(this.items.getItems().filter(item => item.isHarvest()))
    }

    hasChangedItems()
    {
        return this.items.getItems().filter(item => item.isQtyChanged() || item.isPriceChanged()).length > 0;
    }

    isNew()
    {
        return 'new' === this.batch.status;
    }

    isInProgress()
    {
        return 'in_progress' === this.batch.status;
    }

    isDone()
    {
        return 'done' === this.batch.status;
    }

    isMigrated()
    {
        return 'migrated' === this.batch.status;
    }

    fakeMigration()
    {
        this.batch.status = 'migrated';
    }

    getCreatedAt()
    {
        return this.batch.created_at;
    }

    getCompletedAt()
    {
        return this.batch.completed_at;
    }

    getStartAt()
    {
        return this.batch.start_at;
    }

    getEndAt()
    {
        return this.batch.end_at;
    }

    isEditable()
    {
        return StockBatch.editableStatuses.includes(this.getStatus());
    }

    isProcessable()
    {
        return StockBatch.editableStatuses.includes(this.getStatus());
    }

    isFinished()
    {
        return StockBatch.finalStatuses.includes(this.getStatus());
    }

    getId()
    {
        return this.batch.id;
    }

    getCustomerName()
    {
        return this.batch.customer_name;
    }

    canShowStatusModal()
    {
        return this.isInProgress() || (this.isFinished() && this.isTypeFull());
    }

    hasDesiredPrice()
    {
        return this.items.getItems().filter((item) => item.getDesiredPriceWithVat() > 0).length > 0;
    }

    isTypeFull()
    {
        return this.batch.type === 'full';
    }

    isTypeFew()
    {
        return this.batch.type === 'few';
    }

    isTypeOffers()
    {
        return this.batch.type === 'offers';
    }
}
